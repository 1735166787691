<template>
    <div id="main">
        <Login v-if="!islogged" @credential-submitted="onLogin"/>
        <Logout v-else @logout-submitted="onLogout"/>
    </div>    
</template>

<script>
import Login from "@/components/Login.vue"
import Logout from "@/components/Logout.vue"
import axios from "axios"
//import restapi from "@/assets/js/labobinette"

    export default {
        components: {Login,Logout},

        data() {
            return {
                islogged:false,

            }
        },

        created: function() {
            this.isLogged();
        },

        methods: {
            isLogged() {
                var baseurl = "/api/";
                this.islogged=false;
                
                axios.get(baseurl+'?islogged').then(response =>{
                    if (response.data==1) {
                        console.log("logged");
                        this.islogged=true;
                    } else {
                        console.log("notlogged");
                    }
                    
                }).catch(error => {
                    console.log(error);
                    
                })
            },


            onLogin(credentials) {
                var baseurl = "/api/";
                
                axios.post(baseurl,{
                    login:"true",
                    ident:credentials["ident"],
                    password:credentials["password"],                    
                }).then(() =>{
                    this.isLogged();                  
                    
                }).catch(error => {
                    console.log(error);
                    
                })
            },

            onLogout() {
                var baseurl = "/api/";
                
                axios.post(baseurl,{
                    logout:"true",
                }).then(() =>{
                    this.isLogged();
                    
                }).catch(error => {
                    console.log(error);
                    
                })
            }
        }

    }

</script>
