<template>
    <form @submit.prevent="onSubmit">
        <h3>Connection</h3>
    
        <div class="form-group">
            <label>Identifiant</label>
            <input type="text" class="form-control form-control-lg" v-model="ident"/>
        </div>

        <div class="form-group">
            <label>Mot de passe</label>
            <input type="password" class="form-control form-control-lg" v-model="mdp"/>
        </div>

        <button type="submit" class="btn btn-dark btn-lg btn-block">Se connecter</button>

    </form>
</template>

<script>
    export default {
        name: 'Login',


        data() {
            return {
                ident:'',
                mdp:''
            }
        },

        methods: {
            onSubmit() {
                let credentials = {
                ident: this.ident,
                password: this.mdp,
                }
                this.$emit('credential-submitted', credentials)

                this.ident = ''
                this.mdp = ''
            }
        }
    }
</script>