<template>
    <form @submit.prevent="onSubmit">
        <h3>Connecté</h3>
    
        <button type="submit" class="btn btn-dark btn-lg btn-block">Se déconnecter</button>

    </form>
</template>

<script>
    export default {
        name: 'Logout',


        data() {
            return {
                ident:'',
                mdp:''
            }
        },

        methods: {
            onSubmit() {
                this.$emit('logout-submitted');
            }
        }
    }
</script>